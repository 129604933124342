import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "@emotion/styled"
import { css, jsx } from "@emotion/core"

//import Label from "../components/input"

const ContactPage = () => {
  const InputButton = styled.button`
    float: right;
    font-family: PT Serif, serif;
    background-color: DarkCyan;
    border-radius: 4px;
    color: LightGray;
    border: none;
    text-decoration: none;
    transition: transform 0.2s; /* Animation */
    margin: 0 auto;
    &:hover {
      color: white;
      background-color: hsla(0, 0%, 0%, 0.8);
    }
  `

  const WebForm = styled.form`
    overflow: auto;
    font-family: PT Serif, serif;
    border-style: bold;
    border-width: 2px;
    &:focus {
      outline: none;
    }
  `

  const InputLabel = styled.label`
    float: left;
    font-family: PT Serif, serif;
    font-size: larger;
  `

  const InputBox = styled.textarea`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    outline-color: hsla(0, 0%, 0%, 0.8);
    background-color: hsla(0, 0%, 0%, 0.8);
    color: white;
    font-family: Noto Sans, serif;
    resize: vertical;
    &:focus {
      outline: none;
    }
  `

  const InputBar = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    outline-color: hsla(0, 0%, 0%, 0.8);
    background-color: hsla(0, 0%, 0%, 0.8);
    color: white;
    font-family: Noto Sans, serif;
    &:focus {
      outline: none;
    }
  `

  return (
    <Layout>
      <SEO title="Contact Us" />
      <div>
        <h1>Contact Info</h1>
        <h2>Drop Us a Line</h2>
        <p>
          {" "}
          Give us your contact information, and a brief message, and we'll get
          back to you as soon as possible.
        </p>
      </div>
      <WebForm
        name="contact"
        netlify
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <p>
          <InputLabel>Name</InputLabel>

          <InputBar type="text" name="name" placeholder="Name" />
        </p>
        <p>
          <input type="hidden" name="form-name" value="contact" />
        </p>
        <p>
          <InputLabel>Email</InputLabel>

          <InputBar type="email" name="email" placeholder="Email" />
        </p>
        <p>
          <InputLabel>Subject</InputLabel>

          <InputBar type="subject" name="Subject" placeholder="Subject" />
        </p>
        <p>
          <InputLabel>Message</InputLabel>
          <InputBox name="message" placeholder="Your Message" />
        </p>
        <p>
          <InputButton type="submit">Submit</InputButton>
        </p>
      </WebForm>
    </Layout>
  )
}

export default ContactPage
